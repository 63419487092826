import React, { useState } from "react";
import Icon from "components/Icon";
import Overlay from "components/Overlay";

import {
  GalleryImageWrapper,
  NameStyled,
  TeaserStyled,
  IconsWrapper,
  Button,
  InfoWrapperStyled,
  LinkWrapperStyled,
  OverlayStyled,
  ImageStyled,
  TitleWrapperStyled
} from "./styles";

const GalleryTile = ({ image, title, headline, text, link, ...rest }) => {
  const [clicked, setClicked] = useState(false);
  const galleryImage = image[0];
  const fullUri = link?.entry?.fullUri;

  const handleClick = () => {
    setClicked(!clicked);
  };
  return (
    <GalleryImageWrapper onClick={handleClick} {...rest}>
      {(headline || text) && (
        <>
          <InfoWrapperStyled>
            {headline && <NameStyled visible={clicked}>{headline}</NameStyled>}
            {text && <TeaserStyled visible={clicked}>{text}</TeaserStyled>}
            {fullUri && (
              <LinkWrapperStyled>
                <Link to={fullUri || "/" /* if url goes to home */}>
                  {link?.entry?.customText || headline}
                </Link>
              </LinkWrapperStyled>
            )}
          </InfoWrapperStyled>
          <IconsWrapper>
            <Button onClick={handleClick} rotate={!clicked ? "45deg" : 0}>
              <Icon type="closeBig" fillPrimary={"white"} />
            </Button>
          </IconsWrapper>
        </>
      )}
      {title && (
        <TitleWrapperStyled>
          <NameStyled visible={!clicked}>{title}</NameStyled>
        </TitleWrapperStyled>
      )}
      {galleryImage && galleryImage.url && (
        <ImageStyled
          width={"800px"}
          maxWidth={"800px"}
          aspectRatio={galleryImage.width / galleryImage.height}
          fluid={galleryImage}
          objectFit="cover"
        />
      )}
      <OverlayStyled visible={clicked}>
        <Overlay background={"sectionImageBackground"} />
      </OverlayStyled>
    </GalleryImageWrapper>
  );
};

export default GalleryTile;
