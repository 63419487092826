import React from "react";
import { graphql } from "gatsby";
import { styled } from "utils/emotion";
import GalleryTile from "components/GalleryTile";
import Slider from "components/Slider";

const GallerySectionStyled = styled("section")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  backgroundColor: "#120f0b",
  position: "relative",
  zIndex: 1,
  lineHeight: 0
});

export default function Gallery({ pageBuilder }) {
  const { galleryImages } = pageBuilder;

  return (
    <GallerySectionStyled>
      <Slider noPadding gutter={0}>
        {galleryImages.map(
          ({
            galleryImageText,
            galleryImageTitle,
            galleryImageHeadline,
            galleryImage
          }) => (
            <GalleryTile
              key={galleryImageText}
              text={galleryImageText}
              title={galleryImageTitle}
              headline={galleryImageHeadline}
              image={galleryImage}
            />
          )
        )}
      </Slider>
    </GallerySectionStyled>
  );
}

export const query = graphql`
  fragment PageBuilderGalleryQuery on Craft_PageBuilderGallery {
    galleryImages {
      galleryImage {
        ...CraftImageFluid
      }
      galleryImageTitle
      galleryImageHeadline
      galleryImageText
    }
  }
`;
