import { styled } from "utils/emotion";
import Image from "components/Image";

export const GalleryImageWrapper = styled("div")({
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  height: "450px",
  display: "inline-block",
  minWidth: 330,
  width: "100%",
  lineHeight: 1.5
});

export const NameStyled = styled("div")(
  {
    fontSize: "1rem",
    fontWeight: 900,
    letterSpacing: "1px",
    marginBottom: "2rem",
    textTransform: "uppercase",
    transform: "translateX(-100%)",
    transition: "all 200ms ease-out",
    opacity: 0
  },
  props =>
    props.visible && {
      transform: "translateX(0)",
      opacity: 1
    }
);

export const TeaserStyled = styled("p")(
  {
    position: "relative",
    display: "block",
    transform: "translateX(-100%)",
    transition: "all 200ms ease-out",
    opacity: 0,
    transitionDelay: "100ms"
  },
  props =>
    props.visible && {
      transform: "translateX(0)",
      opacity: 1
    }
);

export const IconsWrapper = styled("div")({
  position: "absolute",
  right: "40px",
  top: "40px",
  zIndex: 2
});

export const Button = styled("button")(
  {
    background: "none",
    border: "none",
    cursor: "pointer",
    zIndex: 1,
    outline: "none",
    transition: "transform 400ms"
  },
  props => ({
    transform: `rotate(${props.rotate})`,
    color: props.theme.colorAccent || "white"
  })
);

export const InfoWrapperStyled = styled("div")({
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  padding: "40px 65px 40px 40px",
  zIndex: 2
});

export const TitleWrapperStyled = styled("div")({
  position: "absolute",
  left: 0,
  bottom: 0,
  padding: "40px",
  zIndex: 2
});

export const LinkWrapperStyled = styled("div")({
  position: "absolute",
  bottom: 0,
  a: {
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

export const ImageStyled = styled(Image)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  opacity: 0.65,
  zIndex: 0
});

export const OverlayStyled = styled("div")(
  {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.3,
    transition: "all 200ms ease-out",
    zIndex: 1
  },
  props =>
    props.visible && {
      opacity: 0.8
    }
);
